export const CSS_STYLES = {
  DISPLAY: {
    NONE: "none",
    FLEX: "flex",
    BLOCK: "block",
  },
  VISIBILITY: {
    HIDDEN: "hidden",
    VISIBLE: "visible",
  },
  DISABLED: {
    true: "true",
    false: "false",
  },
  BOTTOM_INFORMATIONAL_MESSAGE: {
    MINIMUM: 100,
    BASE_CHARACTERS: 45,
    UP_SIZE: 20,
  },
};

export const PIPES_CONFIG = {
  retry: 2,
  debounceTime: 1000,
};

/** Nombre de nuestro objeto five9 que gestiona la instancia del chat */
export const LOCAL_STORAGE_FIVE9_ITEM = "siigo-five9-instance";

// Constante para obtener el token de validación
export const USER_DATA: string = "stark:yvdrjs===dk2";

export const KEY_LOCAL_STORAGE_HISTORY_MESSAGES: string = "historyMessagesAivo";

export const INTERVAL_TIME_200: number = 200;

export const SESSION_PROPERTIES = {
  status: "status",
  configurations: "configurations",
  chatData: "chatData",
};

export const HTTP_STATUS = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

// URLS diferenciadas por paises de los posibles lugares donde hacen el llamado del chat
export const COUNTRY_URLS = {
  COLOMBIA_SIIGO_NUBE: [
    "siigonube.siigo.com",
    "qastaging.siigo.com",
    "localhost:4200",
    "localhost:8670",
  ],
  COLOMBIA_SIIGO: ["siigo.com"],
  CHILE: ["siigonube.siigo.cl", "qastaging.siigo.cl"],
  CONTIFICO: [
    //Ecuador
    "contifico.com",
  ],
};
