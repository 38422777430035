/*
  Polyfill para función event.path qu eno se encuentra en algunos navegadores
  https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
*/
export default (() => {
  if (!("path" in Event.prototype))
    Object.defineProperty(Event.prototype, "path", {
      get: function () {
        var path = [];
        var currentElement = this.target;
        while (currentElement) {
          path.push(currentElement);
          currentElement = currentElement.parentElement;
        }
        if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
          path.push(document);
        if (path.indexOf(window) === -1) path.push(window);
        return path;
      },
    });
})();
