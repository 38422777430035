import * as constants from "./constants";

export function documentElementByIdNode(elementID: string) {
  return document.getElementById(elementID);
}

export function documentElementByClass(elementClass: string) {
  return document.getElementsByClassName(elementClass)[0];
}

export function setLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

/**
 * @function setLocalStorageJSON
 * @description guarda en localStorage un JSON
 * @param key
 * @param value
 */
export function setLocalStorageJSON(key: string, value: any) {
  value = JSON.stringify(value);
  setLocalStorage(key, value);
}

export function getLocalStorage(key: string) {
  return localStorage.getItem(key);
}

export function removeLocalStorage(key: string) {
  localStorage.removeItem(key);
}

/**
 * Merge a `source` object to a `target` recursively
 *
 */
export function merge(target: any, source: any) {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], merge(target[key], source[key]));
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source);
  return target;
}

/**
 * @function getSessionProperty
 * @description Obtiene la sesión del chat mientras se está en la encuesta de caritas
 * @param value
 * @return el estado del chat o el objeto con toda la información del chat
 */
export function getSessionProperty(value: string) {
  let five9ChatSession: string =
    getLocalStorage(constants.LOCAL_STORAGE_FIVE9_ITEM) || "{}";

  return JSON.parse(five9ChatSession)[value] ?? null;
}

/**
 * @function validateUrl
 * @description Valida dentro de un array de posibles rutas si la del usuario esta incluida
 * @return Un boolean de acuerdo al resultado de la validación
 */
export function validateUrl(urlsAllowed: string[]): boolean {
  const CURRENT_URL = window.location.href
  return urlsAllowed.find((_url) => CURRENT_URL.includes(_url)) ? true : false
}