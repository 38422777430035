import axios from "axios";
import { catchError, from, interval, map, retry, switchMap, EMPTY, filter, first } from "rxjs";
import { HTTP_STATUS, INTERVAL_TIME_200, KEY_LOCAL_STORAGE_HISTORY_MESSAGES, PIPES_CONFIG, SESSION_PROPERTIES, getLocalStorage, getSessionProperty,  removeLocalStorage} from "../utils";
import { dataFive9Chat } from "./utils";

function getBodyChatHistory() {
    const sessionDataF9 = dataFive9Chat()?.session;
    if(!sessionDataF9) {
        console.error("No se ha obtenido la informacion de la sesion de five9");
        return null
    };
    const urlService = `${sessionDataF9.protocol}//${sessionDataF9.hostname}:${sessionDataF9.port}/appsvcs/rs/svc/conversations/${sessionDataF9.interactionId}/messages`;

    const messageHistory = getLocalStorage(KEY_LOCAL_STORAGE_HISTORY_MESSAGES);
    if(!messageHistory) {
        console.error("No se ha obtenido el historico de mensajes");
        return null
    };

    try {
        const messages = JSON.parse(messageHistory);
        return {
            "urlFive9Service": urlService,
            "chatConversationId": sessionDataF9.interactionId,
            "farmId": sessionDataF9?.farmId,
            "message": messages.filter((item: string) => Boolean(item)).join("\n"),
        }
    } catch (error) {
        console.error("Error al parsear historico de mensajes: ", error);
        return null;
    }
}

function postSendChatHistory(body: any) {
    const urlFive9 = getSessionProperty(SESSION_PROPERTIES.configurations)
        ?.customerManager?.urlSendChatHistory
    if(!urlFive9) {
        console.error("No se ha configurado la url para enviar el historico de mensajes");
        return EMPTY;
    }
    return from(
        axios.post(urlFive9, body)
    ).pipe(
        retry(PIPES_CONFIG.retry),
        catchError((error) => {
            console.error("Error al enviar mensajes a five9: ", error);
            return error;
        })
    );
}

export function sendChatHistory() {
    interval(INTERVAL_TIME_200).pipe(
        map(() => dataFive9Chat()),
        filter((dataF9Stream: any) => dataF9Stream?.session?.agent),
        first(),
        map(() => getBodyChatHistory()),
        switchMap((bodyChatHistoryStream) => bodyChatHistoryStream ? postSendChatHistory(bodyChatHistoryStream) : EMPTY),
        map((responseStream: any) => {
            if(responseStream?.status === HTTP_STATUS.OK) {
                removeLocalStorage(KEY_LOCAL_STORAGE_HISTORY_MESSAGES);
            }
        }),
    ).subscribe();
}