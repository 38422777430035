/*
  Esta función permite ejecutar un callback en un tiempo de inactividad del navegador
  permitiendo ejecutar tareas en segundo plano
  https://developer.mozilla.org/es/docs/Web/API/Window/requestIdleCallback
*/
export default (() => {
  (window as any).requestIdleCallback =
    (window as any).requestIdleCallback ||
    function (callback: Function) {
      const start: number = Date.now();
      return setTimeout(() => {
        callback({
          didTimeout: false,
          timeRemaining: () => {
            return Math.max(0, 50 - (Date.now() - start));
          },
        });
      }, 1);
    };
})();
