/*
  Polyfill para función addEventListener y removeEventListener
  https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
*/
export default (() => {
  if (!Event.prototype.preventDefault) {
    Event.prototype.preventDefault = function (this: any) {
      this.returnValue = false;
    };
  }
  if (!Event.prototype.stopPropagation) {
    Event.prototype.stopPropagation = function (this: any) {
      this.cancelBubble = true;
    };
  }
  if (!Element.prototype.addEventListener) {
    let eventListeners: any[] = [];

    let addEventListener = function (
      this: any,
      type: any,
      listener: any /*, useCapture (will be ignored) */
    ) {
      let self = this;
      let wrapper = (element: any) => {
        element.target = element.srcElement;
        element.currentTarget = self;
        if (typeof listener.handleEvent != "undefined") {
          listener.handleEvent(element);
        } else {
          listener.call(self, element);
        }
      };
      if (type == "DOMContentLoaded") {
        let wrapper2 = (element: any) => {
          if ((window as any).readyState == "complete") wrapper(element);
        };
        (window as any).attachEvent("onreadystatechange", wrapper2);
        eventListeners.push({
          object: this,
          type: type,
          listener: listener,
          wrapper: wrapper2,
        });

        if ((window as any).readyState == "complete") {
          let element: any = new Event("");
          element.srcElement = window;
          wrapper2(element);
        }
      } else {
        this.attachEvent("on" + type, wrapper);
        eventListeners.push({
          object: this,
          type: type,
          listener: listener,
          wrapper: wrapper,
        });
      }
    };
    let removeEventListener = function (
      this: any,
      type: any,
      listener: any /*, useCapture (will be ignored) */
    ) {
      let counter = 0;
      while (counter < eventListeners.length) {
        let eventListener = eventListeners[counter];
        if (
          eventListener.object == this &&
          eventListener.type == type &&
          eventListener.listener == listener
        ) {
          if (type == "DOMContentLoaded")
            this.detachEvent("onreadystatechange", eventListener.wrapper);
          else this.detachEvent("on" + type, eventListener.wrapper);
          eventListeners.splice(counter, 1);
          break;
        }
        ++counter;
      }
    };
    Element.prototype.addEventListener = addEventListener;
    Element.prototype.removeEventListener = removeEventListener;
    if (HTMLDocument) {
      HTMLDocument.prototype.addEventListener = addEventListener;
      HTMLDocument.prototype.removeEventListener = removeEventListener;
    }
    if (Window) {
      Window.prototype.addEventListener = addEventListener;
      Window.prototype.removeEventListener = removeEventListener;
    }
  }
})();
