/*
  Esta función permite crear un evento con nombre y parámetros personalizados
  https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
*/
export default (() => {
  (window as any).CustomEvent =
    window.CustomEvent ||
    function (event: any, parameters: any) {
      parameters = parameters || { bubbles: false, cancelable: false, detail: null };
      var customEvent = document.createEvent("CustomEvent");
      customEvent.initCustomEvent(
        event,
        parameters.bubbles,
        parameters.cancelable,
        parameters.detail
      );
      return customEvent;
    };
})();
