// Nombre del evento que se disparará cuando el chat se debe abrir
export const OPEN_FIVE9_CHAT_EVENT: string = "OpenFive9ChatEvent";

// Nombre del evento que se disparará cuando el chat se debe cerrar
export const CLOSE_FIVE9_CHAT_EVENT: string = "CloseFive9ChatEvent";

// Nombre del evento que se disparará cuando el script cargue correctamente
export const FILE_LOADED_FIVE9_CHAT_EVENT: string = "FileLoadedFive9ChatEvent";

//Nombre del evento que se dispará cuando la encuesta se ha finalizado y se debe enviar
export const SEND_SURVEY_FIVE9_CHAT_EVENT: string = "SendSurveyFive9ChatEvent";

//Nombre del evento que se dispará cuando la encuesta se envio exitosamente
export const SEND_SURVEY_SUCCESSFUL_FIVE9_CHAT_EVENT: string =
  "SendSurveySuccessfulFive9ChatEvent";

//Nombre del evento que se dispará cuando la encuesta se envio y algo salio mal
export const SEND_SURVEY_ERROR_FIVE9_CHAT_EVENT: string =
  "SendSurveyErrorfulFive9ChatEvent";

//Nombre del evento que se dispará cuando se debe cambiar el estado del cargador de chat
export const SURVEY_LOADING_FIVE9_CHAT_EVENT: string =
  "SurveyLoadingFive9ChatEvent";

//Nombre del evento que se dispará cuando se debe mostrar las gracias al final de la encuesta
export const HIDDE_THANKS_RESPONSE_FIVE9_CHAT_EVENT: string =
  "HiddeThanksResponsesFive9ChatEvent";

// Nombre del evento que se dispará para ocultar el histórico de chats
export const HIDDE_THANKS_RESPONSE_FIVE9_CHAT_FIVE9_CHAT_EVENT: string =
  "HideConversationHistoryFive9ChatEvent";

// Nombre del evento que se disparará si el script NO logra cargar correctamente
export const FILE_ERROR_LOADED_FIVE9_CHAT_EVENT: string =
  "FileErrorLoadedFive9ChatEvent";

/** Nombre de evento que guarda el chat en nuestra key */
export const SAVE_FIVE9_CHAT_EVENT = "SaveFive9ChatEvent";

/** Nombre de evento que inicializa la configuración de Five9 */
export const INITIALIZE_CONFIGURATION_FIVE9_CHAT_EVENT =
  "InitializeConfigurationFive9ChatEvent";

/** Nombre de evento que inicia un nuevo chat */
export const INITIALIZE_NEW_FIVE9_CHAT_EVENT: string =
  "InitializeNewFive9ChatEvent";

/** Nombre de evento que maximiza el chat de Five9 */
export const MAXIMIZE_FIVE9_CHAT_EVENT: string = "MaximizeFive9ChatEvent";

/** Nombre de evento que minimiza el chat de Five9 */
export const MINIMIZE_FIVE9_CHAT_EVENT: string = "MinimizeFive9ChatEvent";

export const OPEN_SURVEY_FIVE9_CHAT_EVENT = "OpenSurveyFive9ChatEvent";

export const dispatchChatEvent = (eventName: string, parameters?: any) =>
  window.dispatchEvent(createCustomEvent(eventName, parameters));

export const createCustomEvent = (eventName: string, parameters?: any) =>
  new CustomEvent(eventName, { detail: parameters });

export const removeChatEvent = (
  eventName: string,
  listener: any,
  options?: any
) => window.removeEventListener(eventName, listener, options);

export const IntersectionObserverSupported = () =>
  typeof window !== "undefined"
    ? !!(window as any).IntersectionObserver
    : false;
