// ID para la etiqueta HTML del script que se cargará
export const ID_SCRIPT_FILE_FIVE9_CHAT: string = "Five9ChatScript";

/*
  LOAD_TAG_FIVE9_CHAT
  Nombre del atributo personalizado el cual se podrá poner en la etiqueta HTML deseada para 
  ser analizada y a través del cual se cargará de manera perezosapara el script
*/
export const LOAD_TAG_FIVE9_CHAT: string = "data-siigo-five9";

export const SCRIPT_FILE_FIVE9_CHAT: string =
  "https://app.five9.com/consoles/SocialWidget/five9-social-widget.min.js";

export const SERVICES = {
  FIVE9: {
    DOMAIN: "https://app.five9.com",
    CONTEXT: "consoles"
  }
};

/** Constante propiedad remove en Configuraciones Five9 enviadas desde MF */
export const REMOVE_FIVE9_DEFAULT_PROPERTY = "remove";

/** Constante con el nombre de la propiedad de los campos de Five9 Chat */
export const FIELDS_FIVE9_PROPERTY = "fields";

/** Constante propiedad SURVEY URL */
export const SURVEY_URL_PROPERTY = "survey";

/** Constante personalizada cierre five9 Chat */
export const SUCCESS_CLOSED_FIVE9_SURVEY = "successCompletedSurvey";

/** Constante con los valores de la sesión de usuario */
export const COMPANY_DATA = sessionStorage.getItem("CompanyKey")?.toUpperCase();

/** Constante que identifica el último mensaje enviado por el cliente */
export const LAST_CLIENT_MESSAGE_ID = "last-client-message";

/** Constante que identifica el último mensaje enviado por el agente */
export const LAST_AGENT_MESSAGE_ID = "last-agent-message";

/** Definición del tipo de dato para constante USER_TYPE */
type TypeOfUser = {
  [key: string]: string;
};
/** Constante que identifica el tipo de usuario que interactúa en el chat */
export const USER_TYPE: TypeOfUser = {
  CLIENT: "client",
  AGENT: "agent"
};

/** Constante que define el formato de la hora que se mostrará en el histórico de chat */
export const MESSAGES_TIME_FORMAT: string = "hh:mm a";

export const ID_FIVE9_FRAME_FULL: string = "five9-frame-full";
export const ID_EMBEDDED_FRAME: string = "embedded-frame";
export const ID_SEND_SURVEY_BUTTON: string = "send-survey-button";
export const ID_CARD_SURVEY: string = "card";
export const ID_CARD_CONVERSATION: string = "conversation-card";
export const ID_CARD_THANKS: string = "thanks";
export const ID_CARD_LOADING: string = "loading";
export const CLASS_INITIALIZE_NEW_CHAT_BUTTON: string = "initialize-new-chat-button";
export const CLASS_FIVE9_BUBBLE: string = "five9-frame-minimized";
export const FRAME_STYLES_FIVE9_CHAT = { BOTTOM: "1rem" };


export const CONFIGURATIONS_SCHEMA = {
  type: "string",
  tenant: "string",
  fields: "object",
  profiles: "object",
  title: "string",
  theme: "string",
  logo: "string",
  lang: "string",
  showProfiles: "boolean",
  useBusinessHours: "boolean",
  survey: {
    urlSendSurvey: "string"
  }
};

export const FACES: any = {
  ANGRY: {
    label: "angry",
    value: 0,
  },
  SAD: {
    label: "sad",
    value: 25,
  },
  NEUTRAL: {
    label: "neutral",
    value: 50,
  },
  GOOD: {
    label: "good",
    value: 75,
  },
  HAPPY: {
    label: "happy",
    value: 100,
  },
};

export const UNITS_TYPE_OF_DATE: any = {
  MILLISECONDS: 'milliseconds',
  SECONDS: 'seconds',
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAYS: 'days',
  MONTHS: 'months',
  YEARS: 'years',
  DATES: 'dates'
}