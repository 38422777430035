import {
  getLocalStorage,
  merge,
  setLocalStorageJSON,
  removeLocalStorage,
  documentElementByClass,
  CSS_STYLES,
  getSessionProperty,
  LOCAL_STORAGE_FIVE9_ITEM,
  SESSION_PROPERTIES,
} from "../utils";
import * as constants from "./constants";
import * as chatEvents from "./events";
import { showChat, siigoStylesChat } from "./styles";
import { createSuscriptions } from "./suscriptions";
import { validateObject } from "./utils";
import { informationalMessage } from ".";
import i18nModelInstance from "../models/i18n-model";

/*
  Se declara global la variable requestIdleCallback para no hacer uso siempre de 'window'
  ya que requestIdleCallback viene de window y puede no estar definida en este contexto.
*/
declare var ChatModel: any;
declare var requestIdleCallback: Function;
declare var Five9SocialWidget: any;
let intersectionObserver: IntersectionObserver;

export function attributeListener() {
  let elementsFive9Chat: NodeListOf<HTMLElement> = document.querySelectorAll(
    `[class=${constants.LOAD_TAG_FIVE9_CHAT}]`
  );

  if (elementsFive9Chat.length == 0) {
    /*
      No se despacha un evento FILE_ERROR_LOADED_FIVE9_CHAT_EVENT porque al cargar inicialmente
      la página puede no encontrar selectores, pero luego al terminar de cargar la página si encuentre.
    */
    return console.warn(
      "No se encontró elemento para observar y no se cargará el fichero de Five9"
    );
  }

  const OBSERVER_OPTIONS: Object = {
    root: null,
    rootMargin: "0px",
    threshold: [1],
  };

  intersectionObserver = new IntersectionObserver((entries: any) => {
    if (entries[0].isIntersecting) requestIdleCallback(loadScriptFive9Chat);
  }, OBSERVER_OPTIONS);
  elementsFive9Chat.forEach((element) => {
    intersectionObserver.observe(element);
  });
}

export function loadScriptFive9Chat() {
  if (document.querySelector(`#${constants.ID_SCRIPT_FILE_FIVE9_CHAT}`)) return;

  let scriptElement = document.createElement("script");

  // Evento para saber si el script se cargó correctamente
  scriptElement.addEventListener("load", () => {
    createSuscriptions();
    // Se dispara un evento personalizado para comunicar que el script se cargó correctamente
    chatEvents.dispatchChatEvent(chatEvents.FILE_LOADED_FIVE9_CHAT_EVENT);
  });
  // Evento para saber si hubo algun problema al cargar script
  scriptElement.addEventListener("error", () => {
    // Se despacha el evento comunicando que no se pudo cargar el script
    window.dispatchEvent(
      chatEvents.createCustomEvent(
        chatEvents.FILE_ERROR_LOADED_FIVE9_CHAT_EVENT,
        {
          detail:
            "No se pudo cargar el script de Five9, por favor vuelve a intentar actualizando la página.",
        }
      )
    );
    // Se elimina el elemento ya que no se cargó correctamente
    scriptElement.remove();
  });

  scriptElement.id = constants.ID_SCRIPT_FILE_FIVE9_CHAT;
  scriptElement.src = constants.SCRIPT_FILE_FIVE9_CHAT;
  document.body.appendChild(scriptElement);
  // Se termina el observador ya que hubo carga exitosa del fichero Five9
  intersectionObserver.disconnect();
}

/**
 * @function initializeFive9Configuration
 * @description inicializa la configuración de Five9 Chat y crea un objeto en localStorage
 * para mantener la configuración inicial, se reinicia cada vez que se reinicializa el chat
 */
export function initializeFive9Configuration(configurations: any = {}) {
  let customConfigurations =
    configurations && configurations.chat ? configurations.chat : {};

  if (validateObject(customConfigurations, constants.CONFIGURATIONS_SCHEMA)) {
    i18nModelInstance.initializeI18n(customConfigurations?.language);
    if (customConfigurations?.strict) {
      removeLocalStorage(LOCAL_STORAGE_FIVE9_ITEM);
      chatEvents.dispatchChatEvent(chatEvents.CLOSE_FIVE9_CHAT_EVENT);
    }

    let chatStatus = getSessionProperty(SESSION_PROPERTIES.status);
    if (!chatStatus || chatStatus == ChatModel.Steps.Unknown) {

      customConfigurations.restAPI = constants.SERVICES.FIVE9.CONTEXT
      customConfigurations.rootUrl = `${constants.SERVICES.FIVE9.DOMAIN}/${constants.SERVICES.FIVE9.CONTEXT}`

      setLocalStorageJSON(LOCAL_STORAGE_FIVE9_ITEM, {
        configurations: customConfigurations,
      });
    }
    return;
  }
  removeLocalStorage(LOCAL_STORAGE_FIVE9_ITEM);
  throw new Error("Las configuraciones no cumplen con el esquema");
}

/**
 * @function open
 * @description dependiendo el estado del chat creará una nueva instancia
 * del chat, maximizará el chat o mantendrá la encuesta mientras se contesta.
 */
export function open() {
  if (!getSessionProperty(SESSION_PROPERTIES.status)) {
    const session = {
      status: ChatModel.Steps.Unknown,
      configurations: getSessionProperty(SESSION_PROPERTIES.configurations),
    };
    setLocalStorageJSON(LOCAL_STORAGE_FIVE9_ITEM, session);

    chatEvents.dispatchChatEvent(chatEvents.CLOSE_FIVE9_CHAT_EVENT);
  }
  Five9SocialWidget.addWidget(
    getSessionProperty(SESSION_PROPERTIES.configurations)
  );
  siigoStylesChat();

  showChat();
  const message = getSessionProperty(
    SESSION_PROPERTIES.configurations
  ).informationalMessage;
  if (message) {
    informationalMessage(message);
  }
  if (
    getSessionProperty(SESSION_PROPERTIES.status) == ChatModel.Steps.Finished
  ) {
    chatEvents.dispatchChatEvent(chatEvents.OPEN_SURVEY_FIVE9_CHAT_EVENT);
  }
}

/**
 * @function setChatSession
 * @description genera un objeto en localStorage que mantiene la sesión del chat
 * mientras se responde la encuesta
 * @param _chatSession
 */
export function setChatSession(_chatSession: any) {
  const session = {
    status: ChatModel.Steps.Finished,
    chatData: _chatSession,
    configurations: getSessionProperty(SESSION_PROPERTIES.configurations),
  };
  setLocalStorageJSON(LOCAL_STORAGE_FIVE9_ITEM, session);
}

/**
 * @function setNewStatus
 * @description setea un nuevo status en el objeto personalizado localStorage
 */
export function setNewStatus(newStatus: string) {
  let five9ChatSession: any = JSON.parse(
    getLocalStorage(LOCAL_STORAGE_FIVE9_ITEM) || "{}"
  );
  five9ChatSession.status = newStatus;
  setLocalStorageJSON(LOCAL_STORAGE_FIVE9_ITEM, five9ChatSession);
}

/**
 * @function minimizeChatFive9
 * @description minimiza una instancia de chat Five9 Abierta
 */
export function minimizeChatFive9() {
  let five9Chat: any = documentElementByClass(constants.ID_FIVE9_FRAME_FULL);
  let five9MinimizedBubble: any = documentElementByClass(
    constants.CLASS_FIVE9_BUBBLE
  );

  if (five9Chat && five9MinimizedBubble) {
    five9Chat.style.display = CSS_STYLES.DISPLAY.NONE;
    five9MinimizedBubble.style.display = CSS_STYLES.DISPLAY.BLOCK;
  }
}

/**
 * @function maximizeChatFive9
 * @description maximiza una instancia de Five9 Chat en ejecución
 */
export function maximizeChatFive9() {
  let five9Chat: any = documentElementByClass(constants.ID_FIVE9_FRAME_FULL);
  let five9MinimizedBubble: any = documentElementByClass(
    constants.CLASS_FIVE9_BUBBLE
  );

  if (five9Chat && five9MinimizedBubble) {
    five9Chat.style.display = CSS_STYLES.DISPLAY.BLOCK;
    five9Chat.style.visibility = CSS_STYLES.VISIBILITY.VISIBLE;
    five9MinimizedBubble.style.display = CSS_STYLES.DISPLAY.NONE;
  }
}

/**
 * @function initializeNewChat
 * @description Inicializa un nuevo chat con las configuraciones del 
 * local storage
 */
export function initializeNewChat(): void {
  clearPropertyStartOfSurvey()
  let configurations = {
    chat: merge(getSessionProperty(SESSION_PROPERTIES.configurations), {
      strict: true,
    }),
  };
  chatEvents.dispatchChatEvent(
    chatEvents.INITIALIZE_CONFIGURATION_FIVE9_CHAT_EVENT,
    configurations
  );
  chatEvents.dispatchChatEvent(chatEvents.OPEN_FIVE9_CHAT_EVENT);
};

/**
 * @function clearPropertyStartOfSurvey
 * @description limpia propiedad startOfSurvey utilizada para capturar el tiempo en el que se inserta la encuesta en el chat
 * local storage
 */
export function clearPropertyStartOfSurvey() {
  let five9ChatSession: any = JSON.parse(
    getLocalStorage(LOCAL_STORAGE_FIVE9_ITEM) || "{}"
  );
  five9ChatSession.configurations.survey.startOfSurvey = null
  setLocalStorageJSON(LOCAL_STORAGE_FIVE9_ITEM, five9ChatSession);
}
