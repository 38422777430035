import {
  COUNTRY_URLS,
  CSS_STYLES,
  documentElementByClass,
  documentElementByIdNode,
  removeLocalStorage,
  getSessionProperty,
  LOCAL_STORAGE_FIVE9_ITEM,
  SESSION_PROPERTIES,
  validateUrl,
} from "../utils";
import { SUCCESS_CLOSED_FIVE9_SURVEY, ID_FIVE9_FRAME_FULL } from "./constants";
import { CLOSE_FIVE9_CHAT_EVENT, dispatchChatEvent } from "./events";
import "./suscriptions";
import * as events from "./events";
import i18nModelInstance from "../models/i18n-model";

export function siigoStylesChat() {
  //add button close chat
  let five9Header = documentElementByClass("five9-header");

  let closeButton = documentElementByClass("close-five9");
  if (!closeButton) {
    const newCloseButton = document.createElement("div");
    newCloseButton.classList.add("close-five9");
    newCloseButton.innerHTML = "X";
    newCloseButton.addEventListener("click", hideChat);

    if (five9Header) {
      five9Header.appendChild(newCloseButton);
    }
  }

  // Chat Minimizado
  let five9MinimizeButton = documentElementByIdNode("five9-minimize-button");
  if (five9MinimizeButton) {
    five9MinimizeButton.classList.remove("five9-chat-button");
    five9MinimizeButton.classList.add("five9-chat-button-siigo-minimize");
    five9MinimizeButton.addEventListener("click", () =>
      events.dispatchChatEvent(events.MINIMIZE_FIVE9_CHAT_EVENT)
    );
    let five9Text = five9MinimizeButton.querySelector(".five9-text");
    if (five9Text) five9Text.innerHTML = "";
  }
  let five9MinimizeIcon = documentElementByIdNode("five9-minimize-icon");
  if (five9MinimizeIcon)
    five9MinimizeIcon.innerHTML = "<div class='f9 minimize-icon'></div>";

  let five9MaximizeButton = documentElementByIdNode("five9-maximize-button");
  // Chat Maximizado
  if (five9MaximizeButton) {
    five9MaximizeButton.classList.remove("five9-chat-button");
    five9MaximizeButton.classList.add("five9-chat-button-siigo-maximize");
    let five9Icon = five9MaximizeButton.querySelector(".five9-icon");
    if (five9Icon) {
      five9Icon.classList.remove("five9-icon");
      five9Icon.classList.add("five9-icon-siigo");
      five9Icon.innerHTML = "<i class='f9 icon-siigo-comunicacion-chat'></i>";
      five9MaximizeButton.addEventListener("click", () =>
        events.dispatchChatEvent(events.MAXIMIZE_FIVE9_CHAT_EVENT)
      );
    }
    let five9Text = five9MaximizeButton.querySelector(".five9-text");
    if (five9Text) five9Text.innerHTML = "";
  }

  //Otros estilos
  let fillLogo = documentElementByClass("fill-logo");
  if (fillLogo) {
    let logoImg = fillLogo.querySelector("img");
    if (logoImg) logoImg.classList.add("mt4");
  }
  let emailButton = documentElementByIdNode("redirect-email-button");
  if (emailButton) emailButton.innerHTML = i18nModelInstance.getTranslation("startChat");

  let popup = documentElementByIdNode("five9-popout-button");
  if (popup) popup.remove();
  let profilesLabel = documentElementByIdNode("profiles-label");
  if (profilesLabel) profilesLabel.innerHTML = i18nModelInstance.getTranslation("profile");
  let header = document.querySelector("head");

  let existStyle = documentElementByClass("five9-custom-styles");
  if (!existStyle) {
    let styles = document.createElement("style");
    styles.classList.add("five9-custom-styles");
    styles.innerHTML = `
    .ui-logo {
      padding-top: 5px;
      padding-bottom: 0;
    } 
    .ui-header .ui-logo img {
      height: 30px;
    }
    .hideChat {
      display:none !important;
    }`;
    if (header) header.appendChild(styles);
  }
  if (emailButton) emailButton.innerHTML = i18nModelInstance.getTranslation("sendEmail");
  let maximizeButton = documentElementByIdNode("five9-maximize-button");
  if (maximizeButton) maximizeButton.click();
}

/**
 * @function hideChat
 * @description oculta el chat visualmente
 */
export function hideChat() {
  if (
    getSessionProperty(SESSION_PROPERTIES.status) ===
    SUCCESS_CLOSED_FIVE9_SURVEY
  ) {
    removeLocalStorage(LOCAL_STORAGE_FIVE9_ITEM);
    dispatchChatEvent(CLOSE_FIVE9_CHAT_EVENT);
  }
  let chat = documentElementByClass("five9-frame");
  if (chat) chat.classList.add("hideChat");
}

/**
 * @function showChat
 * @description oculta el chat visualmente
 */
export function showChat() {
  let chat = documentElementByClass("five9-frame");
  if (chat && chat.classList.contains("hideChat"))
    chat.classList.remove("hideChat");
}

/**
 * @function informationalMessage
 * @description Muestra un mensaje informativo debajo del formulario que inicia el chat
 */
export function informationalMessage(message: string) {
  let activeMessageUrls = COUNTRY_URLS.COLOMBIA_SIIGO_NUBE.concat(
    COUNTRY_URLS.CONTIFICO,
    COUNTRY_URLS.COLOMBIA_SIIGO
  );

  if (validateUrl(activeMessageUrls)) {
    let frameFull = documentElementByIdNode(ID_FIVE9_FRAME_FULL);
    let head = document.getElementsByTagName("HEAD")[0];
    let styleMessageFrameFull = document.createElement("style");
    let bottomFive9 = CSS_STYLES.BOTTOM_INFORMATIONAL_MESSAGE.MINIMUM;
    if (
      message.length > CSS_STYLES.BOTTOM_INFORMATIONAL_MESSAGE.BASE_CHARACTERS
    ) {
      let numberOfLines =
        message.length /
        CSS_STYLES.BOTTOM_INFORMATIONAL_MESSAGE.BASE_CHARACTERS;
      bottomFive9 +=
        numberOfLines * CSS_STYLES.BOTTOM_INFORMATIONAL_MESSAGE.UP_SIZE;
    }
    styleMessageFrameFull.innerHTML = `
      .top-message {
        color: white;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 10px; 
      }

      .top-message-siigo {
        position: absolute;
        right: 330px;
        width: 266px;
      }
      
      .top-message-contifico {
        position: absolute;
        left: 330px;
        width: 266px;
      }

      .background-color-siigo {
        background-color: #00aaff;
      }

      .background-color-contifico {
        background-color: #2063f1;
      }
      
      .five9-frame-full-message {
        bottom: ${bottomFive9}px !important;
      }
  
      .five9-minimize-button-message {
        top: 158px !important;
      }
    `;

    let five9MinimizeButton = document.getElementById("five9-minimize-button");
    let divMessage = document.createElement("div");
    divMessage?.classList.add("top-message");
    head.appendChild(styleMessageFrameFull);
    divMessage.innerHTML = message;

    if (validateUrl(COUNTRY_URLS.COLOMBIA_SIIGO)) {
      divMessage?.classList.add("background-color-siigo");
      divMessage?.classList.add("top-message-siigo");
      frameFull?.insertBefore(divMessage, frameFull.firstChild);
    } else if (validateUrl(COUNTRY_URLS.COLOMBIA_SIIGO_NUBE)) {
      divMessage?.classList.add("background-color-siigo");
      five9MinimizeButton?.classList.add("five9-minimize-button-message");
      frameFull?.classList.add("five9-frame-full-message");
      frameFull?.insertBefore(divMessage, frameFull.lastChild);
    } else if (validateUrl(COUNTRY_URLS.CONTIFICO)) {
      divMessage?.classList.add("background-color-contifico");
      divMessage?.classList.add("top-message-contifico");
      frameFull?.insertBefore(divMessage, frameFull.firstChild);
    }
  }
}
