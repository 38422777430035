import {
  open,
  setChatSession,
  initializeFive9Configuration,
  minimizeChatFive9,
  maximizeChatFive9,
  setNewStatus,
} from "./chat";
import * as events from "./events";
import {
  hiddeThanksReponse,
  hideConversationHistory,
  replaceSurvey,
  saveSurvey,
  surveyLoading,
} from "./survey";
import { getSessionProperty, SESSION_PROPERTIES } from "../utils";
import { Subject, switchMap, tap } from "rxjs";
import { postSurvey } from "../models";
import { endOfSurveySuccesfull, surveyLoadingEvent } from ".";
import { sendChatHistory } from "./chat-history";

declare var Five9SocialWidget: any;
declare var ChatModel: any;

export declare var five9SaveChatSuscribe$: any;

function initializeChatConfiguration(event: any) {
  initializeFive9Configuration(event.detail);
}

function saveFive9Survey(event: any) {
  saveSurvey(event.detail);
  event.stopPropagation();
}

function five9SurveyLoading(event: any) {
  surveyLoading(event.detail);
}

function saveFive9Chat(event: any) {
  if (getSessionProperty(SESSION_PROPERTIES.status) != ChatModel.Steps.Finished)
    setChatSession(event.detail);
}

/**
 * @function createSuscriptions
 * @description esta función añade los eventos al objeto Window de Five personalizados
 */
export function createSuscriptions() {
  sendChatHistory();

  five9SaveChatSuscribe$ = new Subject();
  createSaveChatSuscriptionsRxjs();

  window.addEventListener(events.OPEN_FIVE9_CHAT_EVENT, () => open());
  window.addEventListener(events.CLOSE_FIVE9_CHAT_EVENT, () => {
    Five9SocialWidget.removeWidget();

    //Remove chat events
    events.removeChatEvent(events.MINIMIZE_FIVE9_CHAT_EVENT, minimizeChatFive9);
    events.removeChatEvent(events.MAXIMIZE_FIVE9_CHAT_EVENT, maximizeChatFive9);
  });

  window.addEventListener(events.SEND_SURVEY_FIVE9_CHAT_EVENT, saveFive9Survey);
  window.addEventListener(
    events.SURVEY_LOADING_FIVE9_CHAT_EVENT,
    five9SurveyLoading
  );
  window.addEventListener(
    events.HIDDE_THANKS_RESPONSE_FIVE9_CHAT_EVENT,
    hiddeThanksReponse
  );
  window.addEventListener(
    events.HIDDE_THANKS_RESPONSE_FIVE9_CHAT_FIVE9_CHAT_EVENT,
    hideConversationHistory
  );
  window.addEventListener(events.SAVE_FIVE9_CHAT_EVENT, saveFive9Chat);
  window.addEventListener(events.OPEN_SURVEY_FIVE9_CHAT_EVENT, replaceSurvey);
  window.addEventListener("message", function (event) {
    try {
      let data = JSON.parse(event.data);
      if (data.value) data = data.value;
      if (data.step && data.step == ChatModel.Steps.Finished) {
        events.dispatchChatEvent(events.SAVE_FIVE9_CHAT_EVENT, data);
        events.dispatchChatEvent(events.OPEN_SURVEY_FIVE9_CHAT_EVENT);
      } else if (data.step && data.step == ChatModel.Steps.Conversation) {
        setNewStatus(data.step);
      }
    } catch (error) { }
  });
  window.addEventListener(
    events.INITIALIZE_CONFIGURATION_FIVE9_CHAT_EVENT,
    initializeChatConfiguration
  );

  window.addEventListener(events.MAXIMIZE_FIVE9_CHAT_EVENT, maximizeChatFive9);

  window.addEventListener(events.MINIMIZE_FIVE9_CHAT_EVENT, minimizeChatFive9);
}

/**
 * @function createSaveChatSuscriptionsRxjs
 * @description esta función añade los eventos de RXJS que se pueden utilizar
 */
export function createSaveChatSuscriptionsRxjs() {
  five9SaveChatSuscribe$
    .pipe(
      tap(() => surveyLoadingEvent(true)),
      switchMap((response: any) => postSurvey(response)),
      tap(() => surveyLoadingEvent(false))
    )
    .subscribe({
      next: () => endOfSurveySuccesfull(),
    });
}
