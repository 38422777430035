import {
  documentElementByClass,
  documentElementByIdNode,
  CSS_STYLES,
  getSessionProperty,
  SESSION_PROPERTIES,
  getLocalStorage,
  LOCAL_STORAGE_FIVE9_ITEM,
  setLocalStorageJSON,
} from "../utils";
import { setNewStatus, initializeNewChat } from "./chat";
import * as constants from "./constants";
import * as events from "./events";
import * as dayjs from "dayjs";
import { five9SaveChatSuscribe$ } from "./suscriptions";
import { finalize, interval, takeWhile, tap } from "rxjs";
import i18nModelInstance from "../models/i18n-model";

const intervalSurvey = interval(200)

let faceSelected = constants.FACES.HAPPY.value;

/**
 * @function validateMaximiumActiveTime
 * @description Valida si ya paso el tiempo estipulado para mantener la encuesta activa
 * @return Retorna un boolean que indica si se sobrapasa el tiempo estipulado para
 * mantener la encuesta activa
 */
function validateMaximiumActiveTime(): boolean {
  let five9ChatSessionConfigurations: any = getSessionProperty(SESSION_PROPERTIES.configurations);
  let confirm = false
  if (five9ChatSessionConfigurations?.survey?.maximumActiveTime?.time && five9ChatSessionConfigurations?.survey?.startOfSurvey) {
    const typeDate = Object.values(constants.UNITS_TYPE_OF_DATE).includes(five9ChatSessionConfigurations.survey.maximumActiveTime.type) ? five9ChatSessionConfigurations.survey.maximumActiveTime.type : constants.UNITS_TYPE_OF_DATE.MILLISECONDS
    let surveyStartDatePlusMaximumTime = dayjs(five9ChatSessionConfigurations.survey.startOfSurvey).add(five9ChatSessionConfigurations.survey.maximumActiveTime.time, typeDate)
    confirm = surveyStartDatePlusMaximumTime.isBefore(dayjs(new Date()))
  }
  return confirm
}

export function replaceSurvey() {
  let survey: any
  intervalSurvey.pipe(
    tap(() => {
      survey = documentElementByIdNode(constants.ID_EMBEDDED_FRAME);
      if (survey != null) {
        insertSurvey(survey);
      }
    }),
    takeWhile(() => !validateMaximiumActiveTime()),
    finalize(() => initializeNewChat()
    )
  )
    .subscribe();
}

function getMessageUserType(message: any): any {
  const userType: string | undefined = Object.keys(constants.USER_TYPE)
    .find((userType) =>
      message.messageId.includes(constants.USER_TYPE[userType])
    )
    ?.toLocaleLowerCase();
  return userType || constants.USER_TYPE.AGENT;
}

function getAgentData(): any {
  const chatData = getSessionProperty(SESSION_PROPERTIES.chatData);
  return chatData?.session?.agent;
}

export function getMessages(userType: any = null): [] {
  const chatData = getSessionProperty(SESSION_PROPERTIES.chatData),
    messages = chatData?.conversation?.messages;
  let messagesMapped: any = [],
    lastMessageIndexByUserType: any = {},
    lastMessage: any,
    userTypes: string[] = userType
      ? [userType]
      : Object.keys(constants.USER_TYPE).map(
        (userTypeKey: string) => constants.USER_TYPE[userTypeKey]
      );

  messages.forEach((message: any, index: number) => {
    message.userType = getMessageUserType(message);
    lastMessageIndexByUserType[message.userType] = index;

    const minutesDifference: number = lastMessage
      ? dayjs(message.date).diff(dayjs(lastMessage.date), "minute")
      : 0;

    if (minutesDifference || message.userType != lastMessage?.userType)
      message.showHeader = true;

    lastMessage = message;
    if (userTypes.includes(message.userType)) messagesMapped.push(message);
  });

  const messagesUserTypes: string[] = Object.keys(lastMessageIndexByUserType);
  messagesUserTypes.forEach(
    (messageUserType) =>
    (messagesMapped[
      lastMessageIndexByUserType[messageUserType]
    ].isLastMessage = true)
  );
  return messagesMapped;
}

function generateHTMLMessages(conversationMesssages: []): string {
  let htmlMessages: string = "",
    time: string,
    messageHeader: string,
    messageContainerId: string;

  conversationMesssages.forEach((message: any) => {
    time = dayjs(message.date).format(constants.MESSAGES_TIME_FORMAT);
    messageHeader = "";
    messageContainerId = "";

    if (message.showHeader) {
      messageHeader = `
          <div class="message-header">
              <div class="origin">${message.originatorName}</div>
              <div class="display-time">${time}</div>
          </div>
        `;
    }
    if (message.isLastMessage)
      messageContainerId = String(`last-${message.userType}-message`);

    htmlMessages += `
        <div class="message-container-${message.userType}" id="${messageContainerId}">
            <div class="inner-container">
                ${messageHeader}
                <div class="message-container">
                    <div class="message">${message.messageContent}</div>
                </div>
            </div>
        </div>
      `;
  });
  return htmlMessages;
}

/**
 * @function setStartOfSurvey
 * @description setea la hora en que se lanza la encuesta en el localStorage
 */
function setStartOfSurvey(): void {
  let five9ChatSession: any = JSON.parse(
    getLocalStorage(LOCAL_STORAGE_FIVE9_ITEM) || "{}"
  );
  if (!five9ChatSession.configurations.survey.startOfSurvey) {
    five9ChatSession.configurations.survey = {
      ...five9ChatSession.configurations.survey,
      startOfSurvey: new Date()
    }
    setLocalStorageJSON(LOCAL_STORAGE_FIVE9_ITEM, five9ChatSession);
  }
}

function insertSurvey(survey: HTMLElement) {
  setStartOfSurvey()
  survey.style.display = CSS_STYLES.DISPLAY.NONE;
  let frameFull = documentElementByIdNode(constants.ID_FIVE9_FRAME_FULL);
  let head = document.getElementsByTagName("HEAD")[0];
  let styleSurvey = document.createElement("style");
  styleSurvey.innerHTML = `
        .survey {
            display: flex;
            flex-direction: column;
            height: 412px;
            max-width: 320px;
        }
        
        .survey-header {
            background-color: #00aaff;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .survey-body {
            background: #f3f7f9 !important;
            -webkit-box-shadow: 0 0 5px rgb(0 0 0 / 20%);
            box-shadow: 0 0 5px rgb(0 0 0 / 20%);
            display: inline-block;
            justify-content: center;
            align-items: center;
            height: 371px;
            overflow-y: auto;
        }
        
        .card {
            background: white;
            -webkit-box-shadow: 0 0 5px rgb(0 0 0 / 20%);
            box-shadow: 0 0 5px rgb(0 0 0 / 20%);
            border-radius: 15px;
            overflow-x: hidden;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            height: 80%;
            margin: 15px;
        }
        
        .card h2 {
            font-size: 18px;
            margin: 0;
            padding: 0;
        }
        
        .faces {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding-top: 20px;
        }
        
        .face {
            width: 20%;
            cursor: pointer;
            opacity: 0.2;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        
        #happy {
            opacity: 1;
        }
        
        .card img {
            width: 50px;
        }
        
        #answer {
            width: 90%;
            resize: none;
        }
        
        .send-survey {
            margin-top: 0px;
            background: white !important;
            box-shadow: 0 5px 5px rgb(0 0 0 / 20%);
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        
        .send-survey button {
            background-color: #7ac23a !important;
            color: #fff !important;
            font-size: 1.3em !important;
            font-weight: bold !important;
            max-height: auto !important;
            height: 30px !important;
            border-radius: 28px !important;
            width: 200px;
            border: none;
        }

        .loading {
          visibility: hidden;
          position: absolute;
          width: 100%;
          height: 80%;
          background: #f3f7f9 !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .loading img {
          width: 250px;
        }

        .thanks {
            display: none;
            flex-direction: column;
            align-items: center;
            height: 100%;
            width: 100%;
            background-color: white;
        }

        .thanks h2 {
            font-size: 18px;
        }

        .chat-happy-image {
            width: 100%;
            height: 200px;
            background-image: url(https://app.five9.com/consoles/Common/images/chat-happy.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 200px 200px;
        }


        /* Styles to conversation messages */

        #conversation-card{
            background: white;
            color: #615c5c;
            -webkit-box-shadow: 0 0 5px rgb(0 0 0 / 20%);
            box-shadow: 0 0 5px rgb(0 0 0 / 20%);
            border-radius: 10px;
            margin: 15px;
            padding-bottom: 10px;
        }

        /* Styles to client messages */

        div.message-container-client {
            margin-right: 10px;
            height: auto;
            overflow: auto;
        }

        div.message-container-client > div.inner-container {
            float: right;
        }

        div.inner-container {
            width: 85%;
            margin-top: 4px;
            padding: 0;
        }

        div.message-container-client .message-header {
            display: block;
            text-align: right;
            margin-right: 2px;
        }

        div.inner-container .origin {
            display: inline-block;
            font-size: 12px;
            font-weight: bold;
        }

        div.inner-container .display-time {
            display: inline-block;
            font-size: 12px;
            font-weight: normal;
        }

        div.message-container-client div.message-container {
            background-color: #ebf7ff !important;
            -webkit-box-shadow: none !important;
                    box-shadow: none !important;
            border-bottom-right-radius: 0 !important;
            color: #292f33 !important;
        }
        div.message-container {
            padding: 10px 8px;
            height: auto;
            overflow: hidden;
            border-radius: 5px;
        }

        div.message {
            overflow: hidden;
            text-align: left;
            word-wrap: break-word;
            word-break: break-word;
            white-space: normal;
            font-size: 14px !important;
        }


        /* Styles to agent messages */

        div.message-container-agent {
            margin-left: 10px;
            height: auto;
            overflow: auto;
        }

        div.message-container-agent > div.inner-container {
            float: left;
        }
        
        div.message-container-agent .message-header {
            display: block;
            text-align: left;
            margin-left: 2px;
        }

        div.message-container-agent div.message-container {
            background-color: #f6f6f6 !important;
            color: #292f33 !important;
            border-bottom-left-radius: 0 !important;
        }

        .initialize-new-chat-button{
          background-color: transparent;
          color: #00aaff !important;
          font-size: 1em !important;
          font-weight: bold !important;
          border: none;
          margin-top: 15px;
        }
    `;

  head.appendChild(styleSurvey);
  let divSurveyInstance = documentElementByClass("survey");
  if (!divSurveyInstance) {
    let divSurvey = document.createElement("div");
    divSurvey.classList.add("survey");

    const surveyLogo = getSessionProperty(
      SESSION_PROPERTIES.configurations
    ).logo;
    const htmlMessages = generateHTMLMessages(getMessages());
    const htmlInitializeNewChat: string = `
      <button type="button" class="initialize-new-chat-button" data-role="button">${i18nModelInstance.getTranslation(
        "survey.startANewChat"
      )}</button>
    `;
    const agentData = getAgentData();
    const msgAgentWhoHelpedYou = agentData?.name?.length
      ? `${i18nModelInstance.getTranslation(
          "survey.rememberTheAgentWhoHelpedYou",
          { agentName: agentData?.name }
        )}`
      : "";
    const thankYouMessage: string = `
      <h2>
        ${i18nModelInstance.getTranslation(
          "survey.thanksForContactingOurChatService",
          { msgAgentWhoHelpedYou: msgAgentWhoHelpedYou }
        )}
      </h2>`;

    divSurvey.innerHTML = `
          <div class="survey-header">
              <img src="${surveyLogo}" alt="Logo siigo">
          </div>
          <div class="survey-body">
              <div id="conversation-card">
                  ${htmlMessages}
              </div>

              <div id="card" class="card">
                  <h2>${i18nModelInstance.getTranslation("survey.rateOurAttention")}</h2>
                  <div id="faces" class="faces">
                      <div id="angry" class="face">
                          <img src="https://app.five9.com/consoles/SurveyConsole/images/faces/angry.png" alt="">
                          <label>0%</label>
                      </div>
                      <div id="sad" class="face">
                          <img src="https://app.five9.com/consoles/SurveyConsole/images/faces/sad.png" alt="">
                          <label>25%</label>
                      </div>
                      <div id="neutral" class="face">
                          <img src="https://app.five9.com/consoles/SurveyConsole/images/faces/neutral.png" alt="">
                          <label>50%</label>
                      </div>
                      <div id="good" class="face">
                          <img src="https://app.five9.com/consoles/SurveyConsole/images/faces/good.png" alt="">
                          <label>75%</label>
                      </div>
                      <div id="happy" class="face">
                          <img src="https://app.five9.com/consoles/SurveyConsole/images/faces/happy.png" alt="">
                          <label>100%</label>
                      </div>
                  </div>
                  <div>
                      <label id="answer-label" for="answer"></label>
                      <textarea rows="4" cols="55" name="answer" id="answer" data-autogrow="false" maxlength="2048" placeholder="${i18nModelInstance.getTranslation("survey.writeAComment")}"></textarea>
                  </div>
                  <div data-position="fixed" data-tap-toggle="false" data-theme="d" class="send-survey">
                      <button type="submit" id="send-survey-button" data-role="button" data-wrapperels="false" data-iconshadow="false" data-shadow="false" data-corners="false">${i18nModelInstance.getTranslation("survey.send")}</button>
                  </div>
                  ${htmlInitializeNewChat}
              </div>
              <div id="loading" class="loading">
                <img src="https://app.five9.com/consoles/Common/images/waiting.gif" alt="${i18nModelInstance.getTranslation("survey.surveyCharger")}">
              </div>
              <div id="thanks" class="thanks" style="display: none;">
                  ${thankYouMessage}
                  <div class="chat-happy-image"></div>
                  ${htmlInitializeNewChat}
              </div>
          </div>
      `;
    if (frameFull) {
      frameFull.style.bottom = constants.FRAME_STYLES_FIVE9_CHAT.BOTTOM;
      frameFull.append(divSurvey, survey);
    }
    const lastClientMesssageElement = documentElementByIdNode(
      constants.LAST_CLIENT_MESSAGE_ID
    );
    if (lastClientMesssageElement) lastClientMesssageElement.scrollIntoView();
  }
  startFaces();
  let saveSurvey = documentElementByIdNode(constants.ID_SEND_SURVEY_BUTTON);
  if (saveSurvey) saveSurvey.addEventListener("click", mapSurvey);
  startNewChatButtons();
}

function startFaces() {
  let divFaces = documentElementByIdNode("faces");
  if (divFaces)
    divFaces.addEventListener("click", (event: any) => {
      let path = event["path"];
      let face: string = path[1]["id"];
      let facesArray = Object.keys(constants.FACES).map(
        (_face) => constants.FACES[_face].label
      );
      faceSelected = constants.FACES[face.toUpperCase()].value;
      facesArray.forEach((_face) => {
        let divFace = documentElementByIdNode(_face);
        if (divFace)
          face == _face
            ? (divFace.style.opacity = "1")
            : (divFace.style.opacity = "0.2");
      });
    });
}

function startNewChatButtons() {
  const newChatButtonElements: HTMLCollection = document.getElementsByClassName(
    constants.CLASS_INITIALIZE_NEW_CHAT_BUTTON
  );
  if (newChatButtonElements) {
    for (let index = 0; index < newChatButtonElements.length; index++) {
      newChatButtonElements[index].addEventListener(
        "click",
        initializeNewChat
      );
    }
  }
}

function mapSurvey() {
  let saveSurvey = documentElementByIdNode(constants.ID_SEND_SURVEY_BUTTON);
  let answer: any = documentElementByIdNode("answer");
  let answerValue = answer ? answer["value"] : "";
  const _dataFive9Chat = getSessionProperty(SESSION_PROPERTIES.chatData);

  let data = {
    chat: _dataFive9Chat,
    survey: {
      score: faceSelected,
      templateAnswer: answerValue,
      templateQuestion: i18nModelInstance.getTranslation(
        "survey.rateOurAttention"
      ),
    },
  };
  if (saveSurvey) saveSurvey.setAttribute("disabled", CSS_STYLES.DISABLED.true);
  events.dispatchChatEvent(events.SEND_SURVEY_FIVE9_CHAT_EVENT, data);
  clearSurvey();
}

export function saveSurvey(body: any) {
  five9SaveChatSuscribe$.next(body);
}

/**
 * @function endOfSurveySuccesfull
 * @description Función que dispara todos los eventos necesarios al momento de finalizar la encuesta.
 */
export function endOfSurveySuccesfull() {
  events.dispatchChatEvent(events.SEND_SURVEY_SUCCESSFUL_FIVE9_CHAT_EVENT);
  events.dispatchChatEvent(events.HIDDE_THANKS_RESPONSE_FIVE9_CHAT_EVENT);
  events.dispatchChatEvent(
    events.HIDDE_THANKS_RESPONSE_FIVE9_CHAT_FIVE9_CHAT_EVENT
  );
}

/**
 * @function surveyLoadingEvent
 * @description Función que se encarga de enviar el evento del cargador con su respectivo estado.
 */
export function surveyLoadingEvent(active: boolean) {
  events.dispatchChatEvent(events.SURVEY_LOADING_FIVE9_CHAT_EVENT, active);
}

function clearSurvey() {
  faceSelected = constants.FACES.HAPPY.value;
}

export function hiddeThanksReponse() {
  let divCard = documentElementByIdNode(constants.ID_CARD_SURVEY);
  if (divCard) divCard.style.display = CSS_STYLES.DISPLAY.NONE;
  let divThanks = documentElementByIdNode(constants.ID_CARD_THANKS);
  if (divThanks) divThanks.style.display = CSS_STYLES.DISPLAY.FLEX;
  setNewStatus(constants.SUCCESS_CLOSED_FIVE9_SURVEY);
}

export function hideConversationHistory() {
  let divConversationCard = documentElementByIdNode(
    constants.ID_CARD_CONVERSATION
  );
  if (divConversationCard)
    divConversationCard.style.display = CSS_STYLES.DISPLAY.NONE;
}

export function surveyLoading(visible: boolean) {
  if (visible) {
    let divLoading = documentElementByIdNode(constants.ID_CARD_LOADING);
    if (divLoading) divLoading.style.visibility = CSS_STYLES.VISIBILITY.VISIBLE;
  } else {
    let divLoading = documentElementByIdNode(constants.ID_CARD_LOADING);
    if (divLoading) divLoading.style.visibility = CSS_STYLES.VISIBILITY.HIDDEN;
  }
}
