import {
  FIELDS_FIVE9_PROPERTY,
  REMOVE_FIVE9_DEFAULT_PROPERTY,
} from "./constants";

export function dataFive9Chat(): any {
  let keysLocalStorage = Object.keys(window.localStorage);
  let f9KeyLocalStorage = keysLocalStorage.findIndex((value) =>
    value.match(/f9-chat*/)
  );
  let f9ChatLocalStorage = window.localStorage.getItem(
    keysLocalStorage[f9KeyLocalStorage]
  );
  if (f9ChatLocalStorage) f9ChatLocalStorage = JSON.parse(f9ChatLocalStorage);
  return f9ChatLocalStorage;
}

export function sanitizeFields(configurations: any) {
  Object.keys(configurations).map((key: any) => {
    if (key === FIELDS_FIVE9_PROPERTY) {
      Object.keys(configurations[key]).map((field) => {
        Object.keys(configurations[key][field]).map((property) => {
          if (
            property == REMOVE_FIVE9_DEFAULT_PROPERTY &&
            configurations[key][field][property]
          ) {
            delete configurations[key][field];
          }
        });
      });
    }
  });
  return configurations;
}

/**
 * @function validateObject
 * @description valida campos requeridos en un esquema
 * @return boolean true en caso de tener todos los campos correspondientes y
 *  false de lo contrario
 */
export function validateObject(object: any, schema: any): boolean {
  /** Se usa for clasico para poder retornar el valor false con la primer iteracion
   * que no cumpla los condicionales
   */
  for (const key in schema) {
    const expectedType = schema[key];

    if (typeof expectedType === "object") {
      // Si el tipo esperado es un objeto, significa que tenemos una propiedad anidada.
      if (!validateObject(object[key], expectedType)) {
        return false;
      }
    } else if (typeof object[key] !== expectedType) {
      return false;
    }
  }

  return true;
}