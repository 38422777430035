import axios from "axios";
import { from } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { PIPES_CONFIG, SESSION_PROPERTIES, getSessionProperty } from "../utils";

/**
 * @function postSurvey
 * @description Envia toda la data de la encuesta por medio de una petición POST.
 * @params Token: string de autenticación
 *         body: Todo el objeto de la encuesta que se va a enviar en la petición.
 * @return Promesa con la información que retorna la petición POST.
 */
export function postSurvey(body: any) {
  const urlSurvey = getSessionProperty(SESSION_PROPERTIES.configurations).survey
    .urlSendSurvey
  return from(
    axios.post(`${urlSurvey}`, body)
  ).pipe(
    retry(PIPES_CONFIG.retry),
    catchError((error) => {
      console.error("Error saving survey information on data base: ", error);
      return error;
    }));
};

export default {
  postSurvey
};
