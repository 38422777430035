import * as i18next from "i18next";
import * as esTranslation from "../assets/i18n/es.json";
import * as enTranslation from "../assets/i18n/en.json";
import * as esECTranslation from "../assets/i18n/es-EC.json";

const defaultNS = "default";
const fallbackNS = "default";
const AVAILABLE_LANGUAGES = {
  EN: "en",
  ES: "es",
  ES_ES: "es-ES",
  ES_CL: "es-CL",
  ES_MX: "es-MX",
  ES_EC: "es-EC",
};

function validateDefaultLanguage(_language: string): string {
  if (Object.values(AVAILABLE_LANGUAGES).includes(_language))
    return _language;
  else return AVAILABLE_LANGUAGES.ES;
};

export function initializeI18n(_language: string) {
  i18next.init({
    lng: validateDefaultLanguage(_language),
    fallbackLng: AVAILABLE_LANGUAGES.ES,
    defaultNS,
    fallbackNS,
    supportedLngs: Object.values(AVAILABLE_LANGUAGES),
    resources: {
      [AVAILABLE_LANGUAGES.ES]: {
        default: esTranslation,
      },
      [AVAILABLE_LANGUAGES.EN]: {
        default: enTranslation,
      },
      [AVAILABLE_LANGUAGES.ES_EC]: {
        default: esECTranslation,
      },
    },
  });
}

export function getTranslation(key: string, options?: any, _i18n = i18next): string {
  return _i18n.exists(key) ? _i18n.t(key, options) : "";
};

export default {
  initializeI18n,
  getTranslation,
};
